import { createApp } from 'vue'
import App from './App.vue'
import common from './common/common.js'



import router from './router'
import axios from 'axios'

import './assets/style.css'
import VueDragResizeRotate from "@gausszhou/vue3-drag-resize-rotate"
import "@gausszhou/vue3-drag-resize-rotate/lib/bundle.esm.css"
import * as Icons from '@ant-design/icons-vue'


const app = createApp(App)


import { Avatar, Button,Card,Col,Flex, Input, InputNumber, Radio, Row, Slider, Tabs,Drawer, Tooltip, Pagination, Modal, Dropdown, Menu, Form, Select, Spin,ConfigProvider,Upload} from 'ant-design-vue'

app.use(Button)
app.use(Flex)
app.use(Row)
app.use(Col)
app.use(Input)
app.use(Tabs)
app.use(Radio)
app.use(Slider)
app.use(Avatar)
app.use(InputNumber)
app.use(Card)
app.use(Drawer)
app.use(Tooltip)
app.use(Pagination)
app.use(Modal)
app.use(Dropdown)
app.use(Menu)
app.use(Form)
app.use(Select)
app.use(Spin)
app.use(ConfigProvider)
app.use(Upload)


// 配置全局限制方法
app.config.globalProperties.$noMulClicks = common.noMulClicks

for (let iconName in Icons){
    app.component(iconName, Icons[iconName])
}
//基础配置
axios.defaults.baseURL = "https://aidpapi.13524.net";
app.config.globalProperties.$http =axios

app.use(VueDragResizeRotate);
app.use(router);
app.mount('#app')
// createApp(App).use(router).mount('#app')
